import React, { createContext, useState, useEffect } from "react";
import { DateObject } from "react-multi-date-picker";
import axios from "axios";
import Cookies from 'js-cookie';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import debounce from "../utils/debounce"; 

export const DataContext = createContext();

export default function DataContextProvider({ children }) {
  const [textVariable, setTestVariable] = useState("Test Successful!");
  const [searchInput, setSearchInput] = useState("");
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [checkIn, setCheckIn] = useState("");
  const [checkOut, setCheckOut] = useState("");
  const [hotelIdSearch, setHotelSearchId] = useState([]);
  const [adultsCount, setAdultsCount] = useState(2);
  const [childrenCount, setChildrenCount] = useState(0);
  const [roomCount, setRoomCount] = useState(1);
  const [isSRLoading, setIsSRLoading] = useState(true);
  const [activeFilters, setActiveFilters] = useState([]);
  const [value, setValue] = useState([0, 20000]);
  const [dates, setDates] = useState([
    new DateObject(),
    new DateObject().add(1, "day"),
  ]);

  const resetSearchInput = () => {
    setSearchInput("");
  };

  const fetchSearchSuggestions = async (input) => {
    const visitorToken = Cookies.get('visitorToken');
    if (input.length >= 3) {
      try {
        let data = JSON.stringify({
          action: "searchAutoComplete",
          searchAutoComplete: {
            inputText: input,
            searchType: ["byCity", "byState", "byCountry", "byRandom", "byPropertyName"],
            limit: 10,
          },
        });
        let base64EncodedData = btoa(data);
        const response = await axios.get(
          `https://api.mytravaly.com/web/v1/get/requests.js?payload=${base64EncodedData}`,
          {
            headers: {
              'authtoken': 'fdd96becb0409c45581e020f9d58fc85',
              'visitortoken': visitorToken,
              'Content-Type': 'application/json'
            },
          }
        );

        const {
          byPropertyName: { listOfResult: byPropertyNameList },
          byStreet: { listOfResult: byStreetList },
          byCity: { listOfResult: byCityList },
          byState: { listOfResult: byStateList },
          byCountry: { listOfResult: byCountryList },
        } = response.data.data.autoCompleteList;

        const addSourceToItems = (array, source) => {
          return array.map((item) => ({ ...item, source }));
        };

        const combinedListOfResults = [
          ...addSourceToItems(byPropertyNameList, "byPropertyName"),
          ...addSourceToItems(byStreetList, "byStreet"),
          ...addSourceToItems(byCityList, "byCity"),
          ...addSourceToItems(byStateList, "byState"),
          ...addSourceToItems(byCountryList, "byCountry"),
        ];

        return combinedListOfResults;
      } catch (error) {
        console.error("Error fetching autocomplete data: ", error);
        toast.error("An error occurred. Please try again later.", {
          position: "top-right",
          autoClose: 5000,
          theme: "colored",
        });
      }
    }
    return [];
  };

  // Removed debounce function and its useCallback hook
  // const debounceSearch = useCallback(debounce(async (input) => {
  //   const results = await fetchSearchSuggestions(input);
  //   setSearchSuggestions(results);
  // }, 100), []); // Debounce delay of 100ms

  useEffect(() => {
    const fetchSuggestions = async () => {
      const results = await fetchSearchSuggestions(searchInput);
      setSearchSuggestions(results);
    };

    fetchSuggestions();
  }, [searchInput]);

  return (
    <>
      <ToastContainer />
      <DataContext.Provider
        value={{
          textVariable,
          searchInput,
          setSearchInput,
          searchSuggestions,
          setSearchSuggestions,
          checkIn,
          setCheckIn,
          checkOut,
          setCheckOut,
          hotelIdSearch,
          setHotelSearchId,
          adultsCount,
          setAdultsCount,
          childrenCount,
          setChildrenCount,
          roomCount,
          setRoomCount,
          searchResults,
          setSearchResults,
          isSRLoading,
          setIsSRLoading,
          dates,
          setDates,
          activeFilters,
          setActiveFilters,
          // setNewLocation,
          value,
          setValue,
          resetSearchInput,
        }}
      >
        {children}
      </DataContext.Provider>
    </>
  );
}
